import React, { useState, useEffect, useMemo } from 'react';
import './SocialsShare.scss';
import cn from 'classnames';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon
} from 'react-share';

const SocialsShare = ({ children, className, strates }) => {
	const pageLink = typeof window !== 'undefined' ? window.location.href : '';

	return (
		<>
			<div
				className={cn('socials-share', {
					[className]: !!className
				})}>
				<FacebookShareButton url={pageLink}>
					<FacebookIcon size={50} round={true} />
				</FacebookShareButton>
				<TwitterShareButton url={pageLink}>
					<TwitterIcon size={50} round={true} />
				</TwitterShareButton>
				<LinkedinShareButton url={pageLink}>
					<LinkedinIcon size={50} round={true} />
				</LinkedinShareButton>
			</div>
		</>
	);
};

export default SocialsShare;
