import React from 'react';
import './Cover.scss';
import Img from 'gatsby-image';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import Title from '@/components/Title/Title';

const Cover = ({ image, title, subtitle, withOverlay = false }) => (
	<section style={{ backgroundImage: `url(${image})` }} className="news-cover">
		<ContentWrapper className="cover-content">
			<Title level={2}>{title}</Title>
			<Title level={3}>{subtitle}</Title>
		</ContentWrapper>
		{image && image.childImageSharp && (
			<Img className="cover" style={{ height: '100%' }} alt={title} fluid={image.childImageSharp.fluid} />
		)}
		{withOverlay && <div className="overlay"></div>}
	</section>
);

export default Cover;
