import React from 'react';
import './ArticleMd.scss';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import MarkdownRenderer from 'react-markdown-renderer';
import Section from '@/components/Section/Section';
import Socials from '@/components/SocialsShare/SocialsShare';
import { COLORS } from '@/constants';

const { WHITE, LIGHTGREY } = COLORS;

const STRATES = [
	{
		color: WHITE,
		width: '50%'
	},
	{
		color: LIGHTGREY,
		width: '25%'
	}
];

const ArticleMd = ({ article }) => (
	<Section className="article-section" strates={STRATES}>
		<ContentWrapper className="article-md">
			<MarkdownRenderer markdown={article} />
			<Socials />
		</ContentWrapper>
	</Section>
);

export default ArticleMd;
