import React from 'react';
import Layout from '@/components/Layout/Layout';
import { IsMobileProvider } from '@/contexts/IsMobileContext';
import Cover from '@/components/Cover/Cover';
import ArticleMd from '@/components/ArticleMd/ArticleMd';

const NewsPost = ({ pageContext: { locale, localeName, data, url, link } }) => (
	<IsMobileProvider>
		<Layout withCover locale={locale} url={url} activeLink={link}>
			<Cover image={data.cover} title={data.dataNewsGroup.title} subtitle={data.dataNewsGroup.subtitle} withOverlay />
			<ArticleMd article={data.dataNewsGroup.article} />
		</Layout>
	</IsMobileProvider>
);

export default NewsPost;
